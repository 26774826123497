import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {unique_id} from '@bitstillery/common/lib/utils'
import {Icon} from '@bitstillery/common/components'
import {next_tick} from '@bitstillery/common/lib/proxy'
import {MithrilTsxComponent} from 'mithril-tsx-component'

export class FieldCode extends MithrilTsxComponent<any> {
    id = unique_id()

    constructor(vn: m.Vnode<any>) {
        super()
        // Internal representation of the state
        if (vn.attrs.ref[0][vn.attrs.ref[1]]) {
            this._model = vn.attrs.ref[0][vn.attrs.ref[1]].split('')
        } else {
            this._model = Array(vn.attrs.placeholders).fill(null)
        }
    }

    oncreate(vn:m.Vnde<any>) {
        this.$input_selectors = document.querySelectorAll(`.code-${this.id} input`)
        if (vn.attrs.autofocus) {
            this.$input_selectors[0].focus()
        }
    }

    default_behaviour(e) {
        // Focus previous/next input element
        if (['CapsLock', 'Control', 'Delete', 'Tab', 'Shift'].includes(e.key)) return true
        // Allow onpaste event
        if (e.ctrlKey && e.key === 'v') return true
        // Allow select value
        if (e.ctrlKey && e.key === 'a') return true
    }

    view(vn) {
        if (!vn.attrs.ref[0]) return
        const validation = vn.attrs.validation
        const invalid = validation ? validation._invalid : false
        const disabled = vn.attrs.disabled

        return (
            <div className={classes('c-field-code', 'field', vn.attrs.className, {
                [`code-${this.id}`]: true,
                disabled: disabled,
                invalid: invalid && validation.dirty,
                valid: !validation || !invalid,
            })}>
                {vn.attrs.label && (
                    <label>{vn.attrs.label}
                        {vn.attrs.icon && <Icon name={vn.attr.icon}/>}
                        {vn.attrs.validation && <span className="validation">{validation.label}</span>}
                    </label>
                )}

                <div className="placeholders">
                    {(() => {
                        const placeholders = [] as any
                        for (let i = 0; i < vn.attrs.placeholders; i++) {
                            placeholders.push(<input
                                className={classes({invalid: !this._model[i]})}
                                disabled={vn.attrs.disabled}
                                onkeydown={async(e) => {
                                    // Allow common keyboard actions
                                    if (this.default_behaviour(e)) return
                                    if (e.key === 'Backspace') {
                                        this._model[i] = null
                                        vn.attrs.ref[0][vn.attrs.ref[1]] = this._model.join('')
                                        if (i > 0) {
                                            this.$input_selectors[i - 1].focus()
                                        }
                                        return
                                    }

                                    if (e.key === 'ArrowLeft' && i > 0) this.$input_selectors[i - 1].focus()
                                    if (e.key === 'ArrowRight' && i < vn.attrs.placeholders - 1) this.$input_selectors[i + 1].focus()
                                    // Skip function keys that are not catched by default_behaviour and
                                    // check for an allow-list of characters that may enter the inputs.
                                    if (e.key.length !== 1) return true
                                    if (!vn.attrs.characters || vn.attrs.characters.test(e.key)) {
                                        this._model[i] = e.key.toUpperCase()
                                        vn.attrs.ref[0][vn.attrs.ref[1]] = this._model.join('')

                                        if (i === vn.attrs.placeholders - 1) {
                                            if (vn.attrs.validation && !vn.attrs.validation.dirty) {
                                                vn.attrs.validation.dirty = true
                                            }

                                            if (vn.attrs.onchange) {
                                                vn.attrs.onchange(vn.attrs.ref[0][vn.attrs.ref[1]])
                                            }
                                        }

                                        // Focus next element
                                        await next_tick()
                                        if ((i < this._model.length - 1) && this.$input_selectors[i + 1]) {
                                            this.$input_selectors[i + 1].focus()
                                        }
                                        return
                                    }

                                    e.preventDefault()
                                }}
                                onpaste={(e) => {
                                    e.preventDefault()
                                    const value = e.clipboardData.getData('text').trim()
                                    // Trimmed code must fit the placeholders or the paste is ignored
                                    if (value.length !== vn.attrs.placeholders) return

                                    for (let j = 0; j < vn.attrs.placeholders; j++) {
                                        this._model[j] = value[j]
                                    }
                                    vn.attrs.ref[0][vn.attrs.ref[1]] = this._model.join('')
                                    if (vn.attrs.validation && !vn.attrs.validation.dirty) {
                                        vn.attrs.validation.dirty = true
                                    }

                                    vn.attrs?.onchange(vn.attrs.ref[0][vn.attrs.ref[1]])
                                    this.$input_selectors[vn.attrs.placeholders - 1].focus()
                                    return
                                }}
                                placeholder={'...'}
                                type="text"
                                value={this._model[i]}
                            />)
                        }
                        return placeholders
                    })()}
                </div>
                {(() => {
                    if (invalid && validation.dirty) {
                        return <div className="help validation">{typeof invalid.message === 'function' ? invalid.message() : invalid.message}</div>
                    } else if (vn.attrs.help) {
                        return <div class="help">{vn.attrs.help}</div>
                    }
                })()}
            </div>
        )
    }
}
