import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils'
import {FieldCheckbox} from '@bitstillery/common/components'
import {grid_columns} from '@bitstillery/common/lib/collection'
import m from 'mithril'

export class CollectionColumns extends MithrilTsxComponent<any> {

    view(vnode:m.Vnode<any>) {
        let has_selection = vnode.attrs.has_selection
        if (vnode.attrs.collection) {
            const selection = vnode.attrs.collection.state.selection
            has_selection = ['select', 'deselect'].includes(selection.mode)
        }
        const grid_template_cols = grid_columns(vnode.attrs.columns, has_selection)
        return <div className={classes('c-collection-columns', 'c-collection-items')}>
            <div className="item">
                <div className="cells" style={`grid-template-columns: ${grid_template_cols}`}>
                    <div className="cell">{has_selection && <FieldCheckbox
                        disabled={vnode.attrs.collection.state.loading}
                        ref={[vnode.attrs.collection.state.selection, 'all']}
                    />}</div>
                    {vnode.attrs.columns.map((column) => {
                        return <div className={classes('cell', column.className, `cell-type-${column.type ? column.type : 'default'}`)}>
                            {typeof column.name === 'function' ? column.name() : column.name}
                        </div>
                    })}
                </div>
            </div>
        </div>
    }
}
