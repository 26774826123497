import {add_unique_to_array, merge_deep, remove_from_array} from '@bitstillery/common/lib/utils'
import {proxy} from '@bitstillery/common/lib/proxy'
import {generate_filters, is_filter_active} from '@bitstillery/common/lib/filters'
import {FiltersDescription} from '@bitstillery/common/types'
import {copy_object} from '@bitstillery/common/lib/utils'
import {$s, api, events} from '@bitstillery/common/app'
import {logger} from '@bitstillery/common/app'
import EventEmitter from 'eventemitter3'

const PAGE_SIZE = 25

export interface CollectionSetup {
    /** Describes the API endpoint to use */
    bulk?: {}
    endpoint: {
        /** Use the endpoint's meta description to setup filters, transforms & sorting with */
        meta?: boolean
        /** See the common api for supported methods. */
        method: 'get' | 'post' | 'put' | 'delete'
        /**
         * Path to the actual endpoint; FactServer uses RPC-style, Fact2Server REST-style.
         * If not provided, an endpoint transform is expected to fill the path dynamically.
         */
        path: string
    },
    /** The sorting options to pass to the API endpoint; ignore this when using meta */
    sort?: {
        /** Sort by column id */
        by: string
        /** Available sorting options; used in `CollectionSorter` */
        options: [string, string][]
        /** Determines ascending (asc) or descending (desc) sorting order */
        order: 'asc' | 'desc'
    }
}

export interface CollectionState extends CollectionSetup {
    bulk_mode: string
    detail: null | number
    items: any[]
    loading: boolean
    /** Determines whether to query for more when scrolling to the end of the results */
    has_items: boolean
    query: {
        /** An API V1 pagination property, used when the endpoint v2 is false */
        limit?: number
        /** Retrieve items from `offset` in a paginated collection */
        offset: number
        /** An API V2 pagination property, used when the endpoint v2 is true */
        page_size?: number
    },
    /** Needed when the endpoint is not known yet and async initialization is needed. */
    ready: boolean
    /** Keeps track of selected rows */
    selection: {
        /** Toggles inclusive or exclusive mode for ids */
        all: boolean
        /** A reference list of selected artkeys from the backend; useful for counters */
        current: []
        /** The ids to include or exclude, depending on the value of `all` */
        ids: []
        mode: 'select' | 'deselect' | ''
    }
    sort: {
        by: string
        /** Defaults as being used by filter_to_url links */
        defaults: {
            by: string
            order: 'asc' | 'desc'
        }
        options: string[]
        order: 'asc' | 'desc'
    }
    total: number
    view: {
        mode: 'list' | 'grid'
    }
}

export interface CollectionTransforms {
    [x: string]: any
    /** Dynamically alter the collection endpoint */
    endpoint?: Function
    /** When defined, it is used to apply filter metadata once the actual data has returned. */
    filter_metadata?: {
        /** A filter statistics endpoint to query to. */
        endpoint?: String
        /**
         * The transform function to adapt filter state with, once the endpoint meta
         * endpoint returns. Can also be applied without endpoint, as a generic hook
         * to fill filter options with.
         */
        transform: Function
    }
    /** Used to adapt filter values to query data with, until both ends are harmonized. */
    filters_to_query?: Function
    /** Called just after the items returned from the endpoint; used to adapt non-standard endpoint results. */
    items_queried?: (api_result: {result: [], status_code: number, total: number | null}) => Promise<[]>
    /** Takes care of filling the current selection state */
    selection?: Function
}

export class CollectionProxy {
    bulk: any
    events = new EventEmitter()
    filters = {}

    state: CollectionState = proxy({
        bulk_mode: '',
        defaults: {
            by: '',
            order: 'desc',
        },
        detail: null,
        endpoint: {
            meta: false,
            method: '',
            path: '',
        },
        has_items: true,
        items: [],
        loading: true,
        query: {
            limit: PAGE_SIZE,
            offset: 0,
        },
        ready: false,
        selection: {
            all: true,
            current: [],
            ids: [],
            mode: '',
        },
        sort: {
            by: '',
            options: [],
            order: 'asc',
            toggle: false,
        },
        total: 0,
        view: {
            mode: 'list',
        },
    })

    transforms: CollectionTransforms

    /**
     * The collection is initialized from a meta endpoint, which provides
     * sorting, filtering & endpoint information.
     * @param endpoint
     */
    async _init_from_meta(endpoint) {
        const meta_endpoint = `${endpoint.path}/meta`
        logger.info(`[collection] loading meta data: ${meta_endpoint}`)
        const {result} = await api.get(meta_endpoint) as any

        // Setup sorting
        merge_deep(this.state.sort, {
            defaults: {
                by: this.state.sort.by,
                order: this.state.sort.order,
            },
            by: result.sort_definition.default_field.id,
            order: result.sort_definition.default_field.order === 'DESC' ? 'desc' : 'asc',
            options: result.sort_definition.fields.map((i) => [i.id, i.label]),
        })

        const filter_descriptions = {}
        let order = 0

        for (const _filter of result.filter_definitions) {
            if (_filter.filter_type === 'SELECT_SINGLE') {
                filter_descriptions[_filter.name] = {
                    options: _filter.options.map((i) => [i.value, i.label]),
                    selection: _filter.default,
                    serialize: _filter.serialize,
                }
            } else if (_filter.filter_type === 'SELECT_MULTIPLE') {
                let options
                if (_filter.options.some((i) => 'children' in i)) {
                    options = _filter.options.map((i) => {
                        return [i.value, i.label, 0, i.children.length ? i.children.map((j) => [j.value, j.label, 0, []]) : []]
                    })
                } else {
                    options = _filter.options.map((i) => [i.value, i.label])
                }

                filter_descriptions[_filter.name] = {
                    icon: 'filterMultiple',
                    options,
                    serialize: [_filter.serialize],
                }
            } else if (_filter.filter_type === 'SELECT_RANGE') {
                // Adapt the default value to the frontend's format.
                if (_filter.default.max_value === null) {
                    _filter.default.max_value = Infinity
                }
                _filter.default = [_filter.default.min_value, _filter.default.max_value]

                filter_descriptions[_filter.name] = {
                    icon: 'range',
                    infinity: _filter.infinity,
                    scale: [_filter.scale.min_value, _filter.scale.max_value],
                    serialize: [_filter.serialize],
                    unit: _filter.unit,
                }
            } else if (_filter.filter_type === 'TEXT') {
                filter_descriptions[_filter.name] = {
                    icon: 'search',
                    input: '',
                    serialize: _filter.serialize,
                }
            } else if (_filter.filter_type === 'TOGGLE') {
                filter_descriptions[_filter.name] = {
                    icon: 'toggle-switch-outline',
                    serialize: _filter.serialize,
                }
            }

            merge_deep(filter_descriptions[_filter.name], {
                default: _filter.default,
                help: _filter.help,
                placement: {order},
                selection: _filter.default,
                type: _filter.filter_type,
            })

            order += 1
        }

        const filters = generate_filters(filter_descriptions) as any

        // Setup basic transform; the filters can directly be used
        // in query parameters, because they match the backend's API.
        const transforms = {
            filters_to_query: (filters) => {
                const query = {
                    filters: {},
                    page_size: PAGE_SIZE,
                    search_terms: '',
                    sort_by: this.state.sort.by,
                    sort_ascending: this.state.sort.order === 'asc' ? 'ASC' : 'DESC',
                }

                for (const [key, filter] of Object.entries(filters)) {
                    if (is_filter_active(filter)) {
                        if (key === 'search') {
                            query.search_terms = filter.selection.split(' ').join(',')
                        } else {
                            if (filter.type === 'SELECT_RANGE') {
                                query.filters[key] = {
                                    min_value: filter.selection[0],
                                    max_value: filter.selection[1],
                                }
                            } else {
                                query.filters[key] = filter.selection
                            }
                        }
                    }
                }

                return query
            },
        }

        return {filters, transforms}
    }

    async init(setup:CollectionSetup, filters?:FiltersDescription, transforms?:CollectionTransforms) {
        merge_deep(this.state, setup) as CollectionState

        logger.debug(`[collection] init ${setup.endpoint.path}`)

        this.state.sort.defaults = {
            by: this.state.sort.by,
            order: this.state.sort.order,
        }

        // Determine the type of endpoint (Fact2Server/Factserver) from the endpoint description.
        if (this.state.endpoint.method !== 'post' || this.state.endpoint.path.includes('/')) {
            this.state.endpoint.v2 = true
        } else {
            this.state.endpoint.v2 = false
        }

        if ('bulk' in setup) {
            logger.debug('[collection] bulk enabled')
            this.bulk = setup.bulk
            this.bulk.actions = {}
        }

        if (setup.endpoint.meta) {
            const meta = await this._init_from_meta(this.state.endpoint)
            Object.assign(this.filters, meta.filters)
            this.transforms = meta.transforms
            if (transforms) {
                Object.assign(this.transforms, transforms)
            }
        } else {
            Object.assign(this.filters, filters)
            this.transforms = transforms

            if (this.state.endpoint.v2) {
                this.state.query.page_size = PAGE_SIZE
            } else {
                this.state.query.limit = PAGE_SIZE
            }
        }

        this.state.ready = true
    }

    item_deselect(item) {
        if (this.state.selection.all) {
            add_unique_to_array(this.state.selection.ids, item.artkey)
        } else {
            remove_from_array(this.state.selection.ids, item.artkey)
        }
    }

    item_select(item) {
        if (this.state.selection.all) {
            remove_from_array(this.state.selection.ids, item.artkey)
        } else {
            add_unique_to_array(this.state.selection.ids, item.artkey)
        }
    }

    async query():Promise<{result: any, status_code: number, total: number}> {
        this.state.loading = true
        Object.assign(this.state.query, this.transforms.filters_to_query(this.filters))

        const {result, status_code, total} = await api[this.state.endpoint.method](
            this.state.endpoint.path,
            this.state.query,
            this.state.endpoint.v2,
        )

        // Wait for optional additional data to kick in, before trying to render items.
        let items = result as []
        if (this.transforms.items_queried) {
            items = await this.transforms.items_queried({result, status_code, total})
        }

        if (status_code > 299) {
            return {result, status_code, total: 0}
        }
        this.state.total = total

        if (this.state.query.offset > 0) {
            this.state.items.push.apply(this.state.items, items)
        } else {
            this.state.items.splice(0, this.state.items.length, ...items)
        }

        this.events.emit('state_items_updated')

        if (this.state.total === 0) {
            // The currently retrieved items fit with the requested size;
            // assume there are more.
            if (items.length === PAGE_SIZE) {
                this.state.has_items = (items.length === PAGE_SIZE)
            } else {
                this.state.has_items = false
            }
        } else {
            this.state.has_items = (this.state.items.length < this.state.total)
        }

        if (this.state.query.offset === 0 && this.transforms.filter_metadata) {
            // No need to await the filter results.
            for (const filter of Object.values(this.filters)) {
                filter.loading = true
            }

            if (this.transforms.filter_metadata.endpoint) {
                const {result: meta_data} = await api.post(this.transforms.filter_metadata.endpoint, this.state.query, true)
                if (status_code === 404) return {result: meta_data, status_code, total: 0}
                this.transforms.filter_metadata.transform(meta_data)
            } else if (this.transforms.filter_metadata.transform) {
                // Regular one-time filter setup also goes into the transform.
                this.transforms.filter_metadata.transform(this.filters)
            }
        }

        if (this.filters) {
            for (const filter of Object.values(this.filters)) {
                if (filter.loading) {
                    filter.loading = false
                }
            }
        }

        this.state.loading = false
        return {result, status_code, total}
    }

    async reset_query() {
        logger.info('[collection] reset query')
        if (!this.state.endpoint.path) {
            return
        }
        this.state.query.offset = 0
        this.state.items.splice(0, this.state.items.length)
        await this.query()
    }

    reset_selection() {
        this.state.selection.mode = ''
        if (this.transforms && this.transforms.selection) {
            this.transforms.selection()
        }
    }

    select_previous() {
        const current_index = this.state.items.findIndex((i) => i.artkey === $s.context.id)
        if (current_index > 0) {
            const previous_item = copy_object(this.state.items[current_index - 1])

            if (this.state.detail) {
                this.state.detail = previous_item.artkey
            }

            Object.assign($s.context, {data: previous_item, id: previous_item.artkey})
            events.emit('collection:scroll_to_edit_row')
            return previous_item
        } else {
            this.state.detail = null
            Object.assign($s.context, {id: null, data: null, name: null})
            return null
        }
    }

    /**
     * Selects the next item in the collection.
     * @param delete_current - Used in a checklist workflow; the current item's artkey to remove.
     */
    select_next(delete_current:null | Number = null) {
        const current_index = this.state.items.findIndex((i) => {
            return i.artkey === $s.context.id
        })

        if (current_index >= 0) {
            const next_item = copy_object(this.state.items[current_index + 1])

            if (this.state.detail) {
                this.state.detail = next_item.artkey
            }

            merge_deep($s.context, {data: next_item, id: next_item.artkey})

            if (delete_current !== null) {
                this.soft_delete(delete_current)
            }
            events.emit('collection:scroll_to_edit_row')
            return next_item
        } else {
            this.state.detail = null
            Object.assign($s.context, {id: null, data: null, name: null})
            return null
        }

    }

    selection_count() {
        if (this.state.selection.all) {
            return (this.state.total - this.state.selection.ids.length)
        } else {
            return this.state.selection.ids.length
        }
    }

    /**
     * Removes the item with `artkey` from the collection items state.
     * @param artkey - The item's id to look for.
     */
    soft_delete(artkey) {
        this.state.items.splice(this.state.items.findIndex((i:any) => i.artkey === artkey), 1)
        if (this.state.total) {
            this.state.total -= 1
        }
    }

    /**
     * Retrieve the current context item again from the backend
     * and update the collection state with the new data.
     */
    async update_context() {
        const item_index = this.state.items.findIndex(item => item.artkey === $s.context.id)

        if (item_index < 0) {
            // Not in the collection (yet); reset the collection and be done with it.
            await this.reset_query()
            return
        }

        if (this.state.endpoint.v2) {
            // V2 collection endpoints are expected to be able to filter on artkeys.
            const {result: updated_item} = await api[this.state.endpoint.method](
                this.state.endpoint.path,
                {artkeys: [$s.context.id], sort_by: 'artkey'},
                true,
            ) as any

            if (updated_item.length) {
                merge_deep(this.state.items[item_index], updated_item[0])
            }
            // Update the context data with the new item data.
            if ($s.context.data) {
                merge_deep($s.context.data, updated_item[0])
            }

        } else {
            // A V1 endpoint just reloads the collection results instead.
            await this.reset_query()
        }

    }
}

/**
 * Generates a CSS grid template column definition string based on
 * the provided columns and selection status.
 *
 * @param {Array} columns - An array of column objects, each containing a `width` property.
 * @param {boolean} has_selection - A flag indicating whether a selection column should be included.
 * @returns {string} The resulting CSS grid template columns string.
 */
export function grid_columns(columns, has_selection) {
    let grid_template_cols = ''
    if (has_selection) {
        grid_template_cols += '40px '
    } else {
        grid_template_cols += '0px '
    }

    grid_template_cols += columns.filter((i) => {
        if (i.type) {
            let _type = i.type
            if (typeof i.type === 'function') {
                _type = i.type()
            }
            if (_type === 'hidden') {
                return false
            }
        }
        return true
    }).map((i) => {
        if (i.width) {
            return i.width
        } else {
            return '1fr'
        }
    }).join(' ')
    return grid_template_cols
}
