import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes, unique_id} from '@bitstillery/common/lib/utils'
import {Icon} from '@bitstillery/common/components'
import {$t} from '@bitstillery/common/app'

import {Validator} from '../../../lib/validation.tsx'

export interface FieldSelectAttrs extends Validator {
    placeholder?: string
    options: [any, string][] // array of options in object / label
    icon?: string
    disabled?: boolean
    computed?: () => boolean
    loading?: boolean
    ref?: [{}, string]
    help?: string
    translate?: {
        label: boolean
        prefix: string
    }

    validation: any
    className: any

    onclick: (event) => unknown
    onchange: (event) => unknown
    onAfterChange: (unknown) => unknown
}

export class FieldSelect extends MithrilTsxComponent<FieldSelectAttrs> {

    name = unique_id()

    view(vnode: m.Vnode<FieldSelectAttrs>) {
        const validation = vnode.attrs.validation
        const invalid = validation ? validation._invalid : false
        let disabled = vnode.attrs.disabled
        let options = vnode.attrs.options
        if (vnode.attrs.placeholder) {
            options.unshift(['', vnode.attrs.placeholder])
        }

        const selected_value = vnode.attrs.ref[0][vnode.attrs.ref[1]]
        if (options.length && ['', null, undefined].includes(selected_value)) {
            vnode.attrs.ref[0][vnode.attrs.ref[1]] = options[0][0]
        }

        let select_options = options.map((option, index) => {
            const key = `${index}`
            const model_ref = vnode.attrs.ref[0][vnode.attrs.ref[1]]
            const option_is_selected = model_ref === option[0]
            const option_is_on_artkey = model_ref && model_ref['artkey'] && model_ref['artkey'] === option[0]['artkey']

            if (option_is_selected || option_is_on_artkey) {
                return (<option selected value={key}>{vnode.attrs.translate ? $t(`${vnode.attrs.translate.prefix}${option[1]}`) : option[1]}</option>)
            } else {
                return (<option value={key}>{vnode.attrs.translate ? $t(`${vnode.attrs.translate.prefix}${option[1]}`) : option[1]}</option>)
            }
        })

        if (vnode.attrs.loading) {
            select_options = [<option>Loading...</option>]
            disabled = true
        }

        return (
            <div className={classes('c-field-select', 'field', vnode.attrs.className, {
                disabled: disabled,
                invalid: validation && invalid && validation.dirty,
                valid: !validation || !invalid,
            })}>
                {vnode.attrs.label && (
                    <label>{vnode.attrs.label}
                        {vnode.attrs.icon && <Icon name={vnode.attrs.icon}/>}
                        {vnode.attrs.validation && <span className="validation">{validation.label}</span>}
                    </label>
                )}
                <select
                    disabled={disabled}
                    onchange={(e) => {
                        if (vnode.attrs.validation) {
                            vnode.attrs.validation.dirty = true
                        }
                        const index = +e.target.value
                        vnode.attrs.ref[0][vnode.attrs.ref[1]] = options[index][0]
                        // Please do not use this method to adjust state, but only
                        // for non-state side-effects where a watcher is overkill.
                        if (vnode.attrs.onchange) {
                            vnode.attrs.onchange(options[index][0])
                        }
                    }}
                    id={this.name}
                    name={this.name}
                >
                    {select_options}
                </select>
                {(() => {
                    if (invalid && validation.dirty) {
                        return <div className="help validation">{typeof invalid.message === 'function' ? invalid.message() : invalid.message}</div>
                    } else if (vnode.attrs.help) {
                        return <div class="help">{vnode.attrs.help}</div>
                    }
                })()}
            </div>
        )
    }
}
