import m from 'mithril'
import {$s} from '@bitstillery/common/app'

const roles = {
    sales: 'support.roles.sales_nl',
    support: 'support.roles.default',
    tech: 'support.roles.tech',
}

const people = {
    collin: {
        email: 'collin@springtimegroup.nl',
        name: 'Collin van Dijken',
        role: roles.tech,
    },
    cor: {
        name: 'Cor Nuijten',
    },
    frank: {
        name: 'Frank Hofstede',
    },
    gianfranco: {
        name: 'Gian Franco Etzi',
    },
    harry: {
        name: 'Harry van der Veen',
    },
    jeroen: {
        email: 'jeroen@springtimegroup.nl',
        name: 'Jeroen van Veen',
        role: roles.tech,
    },
    klaasjan: {
        email: 'klaasjan@springtimegroup.nl',
        name: 'KlaasJan Elzinga',
        role: roles.tech,
    },
    lars: {
        email: 'lars@springtimegroup.nl',
        name: 'Lars de Ridder',
        role: roles.tech,
    },
    patrick: {
        name: 'Patrick Sinning',
    },
    rene: {
        name: 'René Dolfing',
    },
    tarek: {
        name: 'Tarek Chokair',
    },
    valentina: {
        name: 'Valentina Serna Echavarria',
    },
}

const support_team_tech = {
    collin: people.collin,
    jeroen:  people.jeroen,
    klaasjan: people.klaasjan,
    lars: people.lars,
    unknown: {},
}

support_team_tech.unknown = Object.values(support_team_tech)[Math.floor(Math.random() * Object.values(support_team_tech).length)]

/**
 * Represents the structure and operations on the team,
 * including team members and their details.
 */
export const team = {
    a2bc: {
        discover: support_team_tech,
        portal: {
            gianfranco: people.gianfranco,
            miriam: people.miriam,
            rene: people.rene,
            valentina: people.valentina,
        },
        sales: {
            gianfranco: {...people.gianfranco, role: roles.sales},
            valentina: {...people.valentina, role: roles.sales},
        },
    },
    etr: {},
    msi: {
        discover: support_team_tech,
        portal: {
            cor: people.cor,
            frank: people.frank,
            gianfranco: people.gianfranco,
            harry: people.harry,
            patrick: people.patrick,
            rene: people.rene,
            tarek: people.tarek,
            valentina: people.valentina,
        },
        sales: {
            matthias: {...people.valentina, role: roles.sales},
        },
    },
}

team.etr = team.a2bc
team.msp = team.msi

/**
 * Modifies team members' contact information based on their account
 * status and the application's environment settings.
 */
function support_contact_options() {
    for (const name of Object.keys(people)) {
        if (process.env.MSI_PACKAGE === 'portal') {
            if ($s.support?.manager) {
                // We are logged in; use the support manager data.
                if (!people[name].email) {
                    people[name].email = $s.support.manager.email_address
                }

                if (!people[name].phone) {
                    people[name].phone = $s.support.manager.phone_number
                }
                if (!people[name].mobile) {
                    people[name].mobile = $s.support.manager.mobile_telephone_number
                }
            } else {
                // No account yet; fallback to hardcoded account-specific defaults.
                // This should be handled with a pre_auth call to /portal/account
                if (['msi', 'msp'].includes(process.env.MSI_THEME)) {
                    people[name].email = 'info@movingspirits.nl'
                    people[name].phone = '+31 50 211 09 09'
                } else {
                    people[name].email = 'info@a2bc.com'
                    people[name].phone = '+31 50 211 186 1'
                }
            }
        } else {
            if (!people[name].phone) {
                people[name].phone = '+31 50 211 09 09'
            }
        }

        if (!people[name].role) {
            people[name].role = roles.support
        }
        if (!people[name].img) {
            people[name].img = `/common/img/support/${name}.jpg`
        }
    }
}

export function get_support() {
    support_contact_options()

    const current_team = team[process.env.MSI_THEME][process.env.MSI_PACKAGE]
    let support

    if (process.env.MSI_PACKAGE === 'discover') {
        const support_schedule = {
            0: 'unknown',
            1: 'klaasjan',
            2: 'klaasjan',
            3: 'jeroen',
            4: 'lars',
            5: 'collin',
            6: 'unknown',
        }

        const day = new Date().getDay()
        if (support_schedule[day]) {
            return current_team[support_schedule[day]]
        } else {
            return current_team.unknown
        }
    } else if (process.env.MSI_PACKAGE === 'portal') {
        // Match team keys on image_name
        let matched_name
        if (!$s.identity.token) {
            let selected_team = m.route.get().startsWith('/redeem') ? team[process.env.MSI_THEME].sales : team[process.env.MSI_THEME][process.env.MSI_PACKAGE]
            const support_names = Object.keys(selected_team)
            matched_name = support_names[support_names.length * Math.random() << 0]
        } else {
            matched_name = $s.identity.user.sales_manager?.email_address.split('@')[0]
        }

        if (matched_name in current_team) {
            return current_team[matched_name]
        } else {
            return Object.values(current_team)[1]
        }
    }

    return support
}
